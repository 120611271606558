.SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb .text-content {
  padding: 0 1rem;
}
.PageQuoteDbf15faeDea64586Bd1798a4c1ecf9d7 {
  display: flex;
  flex-wrap: wrap;
}
.MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 {
  padding: 2em;
  background-color: #f9f5e3;
}
.SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc {
  align-items: center;
  color: #030C22;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc .title {
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.ShortHeaderCba15831802f460d999cC5406c94c755 {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .logo {
  width: 8rem;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .logo {
    width: 9rem;
  }

  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .Navigation {
    display: flex;
  }

  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeaderCba15831802f460d999cC5406c94c755 .header-top .logo {
    width: 10rem;
  }
}
.HomeHeader28bfef44234c4827Ab1f47f6aea400a2 {
  background-color: white;
  position: relative;
  color: #030C22;
}

.HomeHeader28bfef44234c4827Ab1f47f6aea400a2 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
}

.HomeHeader28bfef44234c4827Ab1f47f6aea400a2 .header-top-wrapper {
  position: relative;
  height: 6rem;
}

.HomeHeader28bfef44234c4827Ab1f47f6aea400a2 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: white;
}

@media (min-width: 900px) {
  .HomeHeader28bfef44234c4827Ab1f47f6aea400a2 .header-top-wrapper {
    height: 9.25rem;
  }
}
.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .logo {
  width: 10rem;
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 {
    padding: 2.25rem 1.75rem 1rem 1.75rem;
    height: 9rem;
  }

  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .logo {
    width: 12rem;
  }

  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .Navigation {
    display: flex;
  }

  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .Navigation {
    font-size: 120%;
  }
  .DefaultHeaderTop50d011d73e78484098244c5636e7ba62 .logo {
    width: 15.25rem;
  }
}
.MessagePages08b8bb5fDa2247df901038c2bd6103a2 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/sky-background.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

.SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 .header-top {
  z-index: 1;
  position: relative;
}
.SecondaryNav2c7f87975a2045579aa08c73cbe49f71 {
  padding: 1rem 0;
}
.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote > * {
  flex-grow: 1;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote .Image {
  object-fit: cover;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote .TextContent {
  background-color: #6950a8;
  color: white;
  padding: 3rem;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePageB367ec715bb14b958f96Fe1422137433 .PageQuote .Title {
    font-size: 400%;
    line-height: 125%;
  }
}
.MarketingCards10b70aa389ec43bcA40fDac55c437d40 {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .MarketingCard .ImageWrapper img {
  max-height: 400px;
}

@media (min-width: 768px) {
  .MarketingCards10b70aa389ec43bcA40fDac55c437d40 .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .MarketingCard .ImageOverlay {
}

@media (min-width: 768px) {
  .MarketingCards10b70aa389ec43bcA40fDac55c437d40 .CardTextContent {
    width: 25rem;
  }
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCards10b70aa389ec43bcA40fDac55c437d40 .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCards10b70aa389ec43bcA40fDac55c437d40 .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCards10b70aa389ec43bcA40fDac55c437d40 .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 {
  background-color: #500064;
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Montserrat;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 > * {
  text-align: center;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.AboutLevelingPageC2dd4e3c32fd42ed8950583da5204c42 .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 {
  color: #030C22;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .button {
  display: flex;
  justify-content: center;
}

.HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Bebas Neue';
  font-weight: 600;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #780050;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 .button {
    display: block;
    justify-content: start;
  }
}